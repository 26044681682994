import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from '../Navbar/Nav';



function Header() {
    // const { useState } = React;
    // const getInitialState = () => {
    //     const value = "Bootstrap";
    //     return value;
    // };

    // const [value, setValue] = useState(getInitialState);

    // const handleChange = (e) => {
    //     setValue(e.target.value);
    // };




    return (
        <section>
            <div className='bg-light-200 mt-0 	h-48' >
            <div className='md:hidden  '><Nav></Nav></div>
                <div className= ' xs:mt-6 absolute xs:grid-cols-1 grid grid-cols-6  mt-8 '>
             
                    <div className='xs:hidden md:ml-16 col-span-2  flex items-center'> <img src="Image/call icone.svg"></img>
                        <span className='myfont  pl-8'> call for order <br /> +91 9892220253</span>
                    </div>
                    <div className='  md:ml-[200px] col-span-2' >
                        <img className=' xs:ml-8 ' src="Image/logo.png"></img>
                    </div>
                    <div className="flex md:ml-[310px] col-span-2  xs:hidden"  >
                        <img className='p-2' src="Image/fb.svg"></img>
                        <img className='p-2' src="Image/insta.svg"></img>
                        <img className='p-2' src="Image/twitter.svg"></img>
                    </div>
                </div>
                <div className='bg-white border mx-80  rounded-[60px] stroke-[#FEBD113D] xs:hidden  absolute w-[931px] mt-[150px] fixed-top h-20'>
                    <ul class="flex  justify-center gap-10">
                        <li></li>
                        <NavLink to="/" className="font-bold  px-3 py-[25px] text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Home</NavLink>
                        <NavLink to="/About" className="font-medium px-3 py-7 text-slate-700  duration-700 hover:bg-light-500 hover:text-white">About us</NavLink>
                        <NavLink to="/Product1" className="font-medium px-3 py-7 text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Products</NavLink>
                        <NavLink to="/Gallery" className="font-medium px-3 py-7 text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Gallery</NavLink>
                        <NavLink to="/Contact" className="font-medium px-3 py-7 text-slate-700 duration-700  hover:bg-light-500 hover:text-white">Contact us</NavLink>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Header