import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'



const Nav = () => {

    const [nav, setNav] = useState(false)

   
    return (
        <div>
             <div onClick={()=>setNav(!nav)}>
                <img className="absolute mt-8 right-4 h-6 w-6 cursor-pointer " src='Image/navs.svg'></img>
            </div>
            <div  className={nav ? 'fixed top-0 left-0 w-[320px] h-screen bg-white z-10 duration-300' : 'fixed top-0 left-[-100%] w-[320px] h-screen bg-white z-10 duration-300'}>
           
                <div onClick={()=>setNav(!nav)}>
                    <img className="absolute mt-8 right-6 h-6 w-6 cursor-pointer " src='Image/crosss.svg'></img>
                </div>
                <nav>
                <div > <img className=' p-6 ' src="Image/logo.png"></img>
                    </div>
                   <ul className='flex flex-col px-8 mt-8 text-gray-800'>
                    <li className='text-xl py-2 flex ' > <NavLink  className='pr-44 rounded-lg pl-4 py-4' to='/' >  Home </NavLink> </li>
                    <li className='text-xl py-2 flex '> <NavLink className='pr-44 rounded-lg pl-4 py-4'  to='/About' >  About </NavLink>  </li>
                    <li className='text-xl py-2 flex'> <NavLink className='pr-44 rounded-lg pl-4 py-4'  to='/Product1' >  Products </NavLink>  </li>
                    <li className='text-xl py-2 flex'> <NavLink className='pr-44 rounded-lg pl-4 py-4'  to='/Gallery' >  Gallery </NavLink>  </li>
                    <li className='text-xl py-2 flex'> <NavLink className='pr-44 rounded-lg pl-4 py-4'  to='/Contact' >  Contact </NavLink>  </li>
                   </ul>
                </nav>
                <div className="flex p-6"  >
                        <img className='p-2' src="Image/fb.svg"></img>
                        <img className='p-2' src="Image/insta.svg"></img>
                        <img className='p-2' src="Image/twitter.svg"></img>
                    </div>
            </div>
        </div>
    )
}

export default Nav