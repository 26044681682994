import React from 'react'


function About() {
    return (
        <div>
            <section>

                {/* banner image */}
                <div >
                    <img className='w-full' src='Image/abt.svg'></img>
                </div>

                {/* title area */}
                <div className=' top-0 pb-10 bg-light-200'>
                    <div > <h1 className=' text-center  font-[sans] text-[#E62929] text-[96px]'>Sanvee </h1>
                        <h2 className=' text-center font-[sans] text-[#000000] text-[30px]'>THE FLAVOURS</h2>
                    </div>

                    <div className='flex flex-col 
                    items-center justify-center'>
                        <img className='mt-20' src='Image/aboutt.svg'></img>

                    </div>
                    <div className='flex flex-col 
                    items-center justify-center'>
                        <img className='mt-16' src='Image/aboutt2.svg'></img>

                    </div>
                </div>


                {/* team area */}


                <div className='h-[800px] xs:h-auto'>
                    <div > <h1 className=' text-center mt-16 font-[sans] text-[#E62929] text-[52px]'>Team <span className='text-black'>we have </span></h1>
                        <h2 className='  text-center font-[sans] text-[#000000] text-[30px]'>We have awesome team member to help support.</h2>
                    </div>
                    <div className=' mt-20 mx-60 grid grid-cols-3 xs:grid-cols-1 xs:mx-12'>
                        <div className='border-r-2 xs:border-hidden border-[#abb0bc] w-[260px] h-[450px]' ><img className='p-5' src='Image/Group 32.png'></img><br></br>
                            <div className='w-[230px] bg-[#FEBD11] h-[70px] rounded-md'>
                                <h2 className=' pt-2 font-medium text-[20px] text-center text-white'>
                                    Yogesh Kadam
                                </h2>
                                <h3 className='   text-center text-white'>
                                    Director
                                </h3>
                            </div>
                            <p className=' py-2 w-[230px] font-light text-center px-4'>
                                Lisa focuses on providing customers with Organic and Fair Trade Food.
                            </p>
                            <div className='flex gap-4 justify-center pt-2 '>
                                <img src='Image/fb (1).svg'></img>
                                <img src='Image/insta (1).svg'></img>
                                <img src='Image/twit.svg'></img>
                            </div>
                        </div>
                        <div className='border-r-2 xs:border-hidden border-[#abb0bc] w-[260px] h-[450px]' ><img className='p-5' src='Image/Group 33.png'></img><br></br>
                            <div className='w-[230px] bg-[#FEBD11] h-[70px] rounded-md'>
                                <h2 className=' pt-2 font-medium text-[20px] text-center text-white'>
                                    Yogesh Kadam
                                </h2>
                                <h3 className='   text-center text-white'>
                                    Director
                                </h3>
                            </div>
                            <p className=' py-2 w-[230px] font-light text-center px-4'>
                                Lisa focuses on providing customers with Organic and Fair Trade Food.
                            </p>
                            <div className='flex gap-4 justify-center pt-2 '>
                                <img src='Image/fb (1).svg'></img>
                                <img src='Image/insta (1).svg'></img>
                                <img src='Image/twit.svg'></img>
                            </div>
                        </div>
                        <div className='border-r-2 xs:border-hidden border-[#abb0bc] w-[260px] h-[450px]' ><img className='p-5' src='Image/Group 34.png'></img><br></br>
                            <div className='w-[230px] bg-[#FEBD11] h-[70px] rounded-md'>
                                <h2 className=' pt-2 font-medium text-[20px] text-center text-white'>
                                    Yogesh Kadam
                                </h2>
                                <h3 className='   text-center text-white'>
                                    Director
                                </h3>
                            </div>
                            <p className=' py-2 w-[230px] font-light text-center px-4'>
                                Lisa focuses on providing customers with Organic and Fair Trade Food.
                            </p>
                            <div className='flex gap-4 justify-center pt-2 '>
                                <img src='Image/fb (1).svg'></img>
                                <img src='Image/insta (1).svg'></img>
                                <img src='Image/twit.svg'></img>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <div className='bg-wordd h-[600px]'>
                    <div>
                        <h1 className=' text-center pt-8 font-[sans] text-[#FEBD11] text-[70px]' > Words</h1>
                        <h2 className=' text-center pt-0 font-[sans] text-white text-[40px]' > From Our Costumers</h2>
                        <p className=' mx-[440px] text-center pt-10 w-[600px]  text-white '>I met a keen observer who gave me a tip: ‘If you run across a restaurant where you often see priests eating with priests, or sporting girls with sporting girls, you may be confident that it is good. Those are two classes of people who like to eat well and get their money’s worth.</p>
                        <h2 className=' text-center pt-10 font-[sans] text-white text-[40px]' > Jhohn doe</h2>
                    </div>

                </div> */}
                <div className='grid grid-cols-2 xs:grid-cols-1 bg-gradient-to-r from-green-400 to-blue-500  h-32 xs:h-60'>
                    <div>
                        <h2 className='text-center pt-12 text-white font-serif  font-semibold text-3xl'>Your Comfort is Our Priority</h2>
                    </div>
                    <div className='pt-10 mx-20 '>
                        <button className="h-12 xs:px-14 rounded-[50px] bg-white  right-0 px-20 text-lg font-medium text-light-500 duration-300 hover:bg-gray-900 ">Visit Shop</button>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default About