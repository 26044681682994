// export const Categories = [
//   {
//     id: 1,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Almond",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 2,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Badam milk",
//     Image: '/Image/Pharmaceutical.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 3,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Hazel nuts",
//     Image: '/Image/Fruit juices.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 4,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Honey & Lemon",
//     Image: '/Image/Beverages.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 5,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "coconut",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 6,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "cocopine",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 7,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Dry Fruits",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 8,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Orage",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 9,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Lemon",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 10,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 11,
//     categoryId: 1,
//     category: 'Confectionery & Gum based product',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 12,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Apple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 13,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Orange",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 14,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Lemon",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 15,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Lime",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 16,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Mango",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 17,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 18,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Cola",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 19,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Grape",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 20,
//     categoryId: 2,
//     category: 'Beverages',
//     title: "Cherry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 21,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Almond",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 22,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Apple",
//     Image: '/Image/Pharmaceutical.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 23,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Banana",
//     Image: '/Image/Fruit juices.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 24,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Black Current",
//     Image: '/Image/Beverages.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 25,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Orange",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 26,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Lime",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 27,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Mixed Fruits",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 28,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Mango",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 29,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Grape",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 30,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Red Current",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 31,
//     category: 'Biscuits & Bakery',
//     title: "cream",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 32,
//     category: 'Biscuits & Bakery',
//     title: "Butter",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 33,
//     category: 'Biscuits & Bakery',
//     title: "Butterscitch",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 34,
//     category: 'Biscuits & Bakery',
//     title: "chocolate",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 35,
//     category: 'Biscuits & Bakery',
//     title: "cream vanilla",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 36,
//     category: 'Biscuits & Bakery',
//     title: "cinnamom",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 37,
//     category: 'Biscuits & Bakery',
//     title: "vannilla",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 38,
//     category: 'Biscuits & Bakery',
//     title: "orange",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 39,
//     category: 'Biscuits & Bakery',
//     title: "lemon",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 40,
//     category: 'Biscuits & Bakery',
//     title: "orange mint",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 41,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Almond",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 42,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Kesar pista",
//     Image: '/Image/Pharmaceutical.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 43,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Kulfi",
//     Image: '/Image/Fruit juices.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 44,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Rose milk",
//     Image: '/Image/Beverages.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 45,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Blue berry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 46,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Cofee",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 47,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Vanilla",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 48,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Almond",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 49,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Apple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 50,
//     category: 'Dairy Product Frozen dessertd',
//     title: "Banana",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 51,
//     category: 'Pharmaceutical',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 52,
//     category: 'Pharmaceutical',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 53,
//     category: 'Pharmaceutical',
//     title: "Apple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 54,
//     category: 'Pharmaceutical',
//     title: "Orange",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 55,
//     category: 'Pharmaceutical',
//     title: "Lemon",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 56,
//     category: 'Pharmaceutical',
//     title: "Lime",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 57,
//     category: 'Pharmaceutical',
//     title: "Mango",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 58,
//     category: 'Pharmaceutical',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 59,
//     category: 'Pharmaceutical',
//     title: "Cola",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 60,
//     category: 'Pharmaceutical',
//     title: "Grape",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 61,
//     category: 'Pharmaceutical',
//     title: "Cherry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 62,
//     category: 'Fruity',
//     title: "Anaar",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 63,
//     category: 'Fruity',
//     title: "Apple",
//     Image: '/Image/Pharmaceutical.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 64,
//     category: 'Fruity',
//     title: "Apricot",
//     Image: '/Image/Fruit juices.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 65,
//     category: 'Fruity',
//     title: "Banana",
//     Image: '/Image/Beverages.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 66,
//     category: 'Fruity',
//     title: "Black Current",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 67,
//     category: 'Fruity',
//     title: "Black jamun",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 68,
//     category: 'Fruity',
//     title: "Blackberry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 69,
//     category: 'Fruity',
//     title: "Blueberry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 70,
//     category: 'Fruity',
//     title: "Cherry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 71,
//     category: 'Fruity',
//     title: "Chikco",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 72,
//     category: 'Emulsions',
//     title: "Kala Khatta",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 73,
//     category: 'Emulsions',
//     title: "Apple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 74,
//     category: 'Emulsions',
//     title: "Lemon",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 75,
//     category: 'Emulsions',
//     title: "Lemon Ginger",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 76,
//     category: 'Emulsions',
//     title: "Black Current",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 77,
//     category: 'Emulsions',
//     title: "Mango",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 78,
//     category: 'Emulsions',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 79,
//     category: 'Emulsions',
//     title: "Cola",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   }, {
//     id: 80,
//     category: 'Emulsions',
//     title: "Grape",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 81,
//     category: 'Emulsions',
//     title: "Cherry",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 82,
//     category: 'IMFL',
//     title: "Almond",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 83,
//     category: 'IMFL',
//     title: "Badam milk",
//     Image: '/Image/Pharmaceutical.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 84,
//     category: 'IMFL',
//     title: "Hazel nuts",
//     Image: '/Image/Fruit juices.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 85,
//     category: 'IMFL',
//     title: "Honey & Lemon",
//     Image: '/Image/Beverages.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 86,
//     category: 'IMFL',
//     title: "coconut",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 87,
//     category: 'IMFL',
//     title: "cocopine",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 88,
//     category: 'IMFL',
//     title: "Dry Fruits",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 89,
//     category: 'IMFL',
//     title: "Orage",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 90,
//     category: 'IMFL',
//     title: "Lemon",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },
//   {
//     id: 91,
//     category: 'IMFL',
//     title: "Pineapple",
//     Image: '/Image/Powder Flavours.png',
//     cName: 'nav-text'
//   },


// ];


// export const filterCat = [
//   {
//     id: 1,
//     category: 'Confectionery & Gum based product',
//     // title: "Almond",
//     Image: '/Image/ic1.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 2,
//     category: 'Beverages',
//     // title: "Almond",
//     Image: '/Image/ic1.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 3,
//     category: 'Fruit juices other Fruit based Beverages',
//     title: "Badam milk",
//     Image: '/Image/ic2.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 4,
//     category: 'Biscuits & Bakery',
//     title: "Hazel nuts",
//     Image: '/Image/ic3.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 5,
//     category: 'Confectionery & Gum based product',
//     title: "Honey & Lemon",
//     Image: '/Image/ic4.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 6,
//     category: 'Dairy Product Frozen dessertd',
//     title: "coconut",
//     Image: '/Image/ic5.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 7,
//     category: 'Confectionery & Gum based product',
//     title: "cocopine",
//     Image: '/Image/ic6.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 8,
//     category: 'Pharmaceutical',
//     title: "Dry Fruits",
//     Image: '/Image/ic7.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 9,
//     category: 'Pharmaceutical',
//     title: "Orage",
//     Image: '/Image/ic8.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 10,
//     category: 'Confectionery & Gum based product',
//     title: "cocopine",
//     Image: '/Image/ic6.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 11,
//     category: 'Pharmaceutical',
//     title: "Dry Fruits",
//     Image: '/Image/ic7.svg',
//     cName: 'nav-text'
//   },
//   {
//     id: 12,
//     category: 'Pharmaceutical',
//     title: "Orage",
//     Image: '/Image/ic8.svg',
//     cName: 'nav-text'
//   },

// ]
export const Categories = [
  {
    id: 1,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Almond",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 2,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Badam milk",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 3,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Hazel nuts",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 4,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Honey & Lemon",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 5,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "coconut",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 6,
    categoryId: 2,
    category: 'Beverages',
    title: "Apple",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 7,
    categoryId: 2,
    category: 'Beverages',
    title: "Orange",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 8,
    categoryId: 2,
    category: 'Beverages',
    title: "Lemon",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 9,
    categoryId: 2,
    category: 'Beverages',
    title: "Mango",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 10,
    categoryId: 2,
    category: 'Beverages',
    title: "Pineapple",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  },
  {
    id: 11,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Banana",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 12,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Black Current",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 13,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Orange",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 14,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Lime",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 15,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Mixed Fruits",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  },
  {
    id: 16,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "Butter",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 17,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "Butterscitch",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 18,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "chocolate",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 19,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "cinnamom",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 20,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "orange mint",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },
  {
    id: 21,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Kesar pista",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 22,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Kulfi",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 23,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Rose milk",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 24,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Blue berry",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 25,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Cofee",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },
  {
    id: 26,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Mango",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 27,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Pineapple",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 28,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Apple",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 29,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Orange",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 30,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Lemon",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },
  {
    id: 31,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Dill",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 32,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Garlic",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 33,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Almond",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 34,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Badam",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 35,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Cashewnut",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },
  {
    id: 36,
    categoryId: 8,
    category: 'Sweet',
    title: "Ma Mala",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 37,
    categoryId: 8,
    category: 'Sweet',
    title: "Mawa Kulfi",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 38,
    categoryId: 8,
    category: 'Sweet',
    title: "Kulfi",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 39,
    categoryId: 8,
    category: 'Sweet',
    title: "Jamoon",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 40,
    categoryId: 8,
    category: 'Sweet',
    title: "Gulkhand",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },

];


export const filterCat = [
  {
    id: 1,
    category: 'Confectionery & Gum based product',
    Image: '/Image/ic1.svg',
  },
  {
    id: 2,
    category: 'Beverages',
    Image: '/Image/ic2.svg',
  },
  {
    id: 3,
    category: 'Fruit juices other Fruit based Beverages',
    Image: '/Image/ic3.svg',
  },
  {
    id: 4,
    category: 'Biscuits & Bakery',
    Image: '/Image/ic4.svg',
  },
  {
    id: 5,
    category: 'Dairy Product Frozen dessertd',
    Image: '/Image/ic7.svg',
  },  {
    id: 6,
    category: 'Pharmaceutical',
    Image: '/Image/ic6.svg',
  },  
  {
    id: 7,
    category: 'Spicy Nutty',
    Image: '/Image/ic6.svg',
  },  
  {
    id: 8,
    category: 'Sweet',
    Image: '/Image/ic6.svg',
  },  

]