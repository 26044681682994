import React from 'react'
import Carousel from '../Carousel/Carousel'



function Home() {

  // let [product, setProduct] = useState(data);



  return (



    <div className='bg-light-200'>


      <div>
        <img className='max-h-[550px] object-cover w-full md:shrink-0' src='Image/banne.svg'></img>
      </div>

      {/* special product Area */}

      <div>
        <div className=' slide mt-4 flex justify-center  '>
          <img src='Image/cofee.png'></img>
        </div>
        <div className=' flex justify-center  '>
          <img className='object-cover  md:shrink-0 xs:w-[250px] xs:mt-[-30px]' src='Image/sp group.svg'></img>
        </div>
        <div className='mx-10 my-20'>
        <Carousel/>
        </div>
     
        {/* <div className=' mx-16 p-4 py-16 grid md:grid-cols-3 xs:gap-7 scroll-smooth hover:scroll-auto' >
          <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer ' src='Image/product1.png'></img>
          <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer' src='Image/product2.png'></img>
          <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer' src='Image/product3.png'></img>
        </div> */}
      </div>

      {/* Trending Product Area */}

      <div className=' mt-8 xs:h-[1600px] '>
        <div className='absolute w-full h-full ' >
          <h1 className='text-center xs:text-3xl md:text-5xl font-bold text-white mt-8 '>Trending Products</h1>

          <div className='grid grid-cols-4 xs:grid-cols-1  mx-20 pt-10 '>
            <div className='bg-white xs:w-full w-2/3 rounded-lg my-10 text-center'>
              <div>
                <div >
                <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer ' src='Image/Confectionery.jpg'></img>
              </div>
              <div className='p-4 '>
                <span >Confectionery</span>
              </div>
              </div>
            </div>
            <div className='bg-white xs:w-full w-2/3 rounded-lg my-10 text-center '>
              <div >
                <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer ' src='Image/Beverages.png'></img>
              </div>
              <div className='p-4 '>
                <span>Beverages</span>
              </div>

            </div>
            <div className='bg-white xs:w-full rounded-lg w-2/3 my-10 text-center'>
              <div >
                <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer ' src='Image/Fruit juices.png'></img>
              </div>
              <div className='p-4 '>
                <span>Fruit juices</span>
              </div>

            </div>
            <div className='bg-white hover:scale-105 rounded-lg xs:w-full w-2/3 my-10 text-center duration-500 cursor-pointer'>
              <div >
                <img src='Image/Pharmaceutical.png'></img>
              </div>
              <div className='p-4 '>
                <span>Pharmaceutical</span>
              </div>



            </div>
            <div className='bg-white xs:hidden w-2/3 hover:scale-105 rounded-lg my-10 text-center duration-500 cursor-pointer'>
              <div >
                <img src='Image/Biscuits & Bakery.png'></img>
              </div>
              <div className='p-4 '>
                <span>Biscuits & Bakery</span>
              </div>
            </div>
            <div className='bg-white xs:hidden w-2/3 hover:scale-105 rounded-lg my-10 text-center duration-500 cursor-pointer '>
              <div >
                <img src='Image/Savouries & Snacks.png'></img>
              </div>
              <div className='p-4 '>
                <span>Savouries & Snacks</span>
              </div>

            </div>
            <div className='bg-white xs:hidden hover:scale-105 rounded-lg w-2/3  my-10 text-center duration-500 cursor-pointer'>
              <div >
                <img src='Image/Powder Flavours.png'></img>
              </div>
              <div className='p-4 '>
                <span>Powder Flavours</span>
              </div>
            </div>

            <div className='bg-white xs:hidden hover:scale-105 rounded-lg w-2/3 my-10 text-center duration-500 cursor-pointer'>
              <div >
                <img src='Image/Dairy Product.png'></img>
              </div>
              <div className='p-4 '>
                <span>Dairy Product</span>
              </div>
            </div>
            {/* <span className='text-[#FEBD11] text-center'>View More ...</span> */}
          </div>
        </div>
        <img className='md:h-[850px] w-full xs:h-[1600px]  ' src='Image/Rectangle 15.jpg' ></img>
      </div>

      {/* Happy Hours Area */}

      <div className='grid md:grid-cols-2 xs:hidden '>
        <div className='p-2'>
          <img className='xs:w-1/2' src='Image/mix juice.png' ></img>
        </div>
        <div className='pt-10 '>
          <img className='mx-60 xs:mx-[80px]' src='Image/glass.png'></img>
          <h1 className='text-center sm:text-4xl md:text-5xl font-bold text-[#082161] mt-8 '>Let's Happy Hours</h1>
          <p className=' my-10 mx-10 text-[#082161] font-medium leading-8 text-center xs:hidden '>We are proud of our coffee shop with the highest-quality products, most inviting stores, friendliest staff and the best coffee in the world</p>
          <button className='w-[117px] h-[40px] mx-60 hover:bg-gray-900 duration-300 rounded-[20px] bg-[#FEBD11] text-white  '> View More</button>
        </div>
      </div>

      {/* best of us area */}

      <div className=' mt-8 '>
        <div className='absolute w-full  ' >
          <h1 className='text-center xs:text-4xl sm:text-4xl md:text-5xl font-bold text-white mt-8 '>Best of us  </h1>

          <div className=' mx-10  p-4 py-16 grid md:grid-cols-3 xs:gap-7' >
            <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer rounded-md ' src='Image/best of us 3.jpg'></img>
            <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer rounded-md ' src='Image/best of us 2.jpg'></img>
            <img className='shadow-ig hover:scale-105 duration-500 cursor-pointer rounded-md' src='Image/best of us 1.jpg'></img>
          </div>
          <img className='pl-[600px] xs:hidden' src='Image/slide.svg'></img>
        </div>

        <img className='md:h-[700px] w-full xs:h-[750px] ' src='Image/Rectangle 15.jpg' ></img>
      </div>

      {/* Testimonial */}

      <div className=' h-[550px] xs:h-[600px] top-0 bg-white  '>

        <h1 className='text-center xs:text-4xl sm:text-4xl md:text-5xl font-bold text-[#000000] pt-20 '>Testimonial</h1>
        <h2 className='text-center pt-3  font-light text-[#000000]  '>Our costumar says</h2>
        <div className=' xs:grid-cols-4 mt-10 md:flex  '>
          <div className='w-4/12'>
          </div>
          <div className='xs:pl-16' >
            <img src='Image/Ellipse 13.png'></img><br></br>
            <span className=' xs:pl-12 pl-2 font-semibold '>John doe</span>
          </div>
          <div className='pl-20 xs:pl-10 flex'>
            <img className='xs:hidden' src='Image/Group 152 (3).svg'></img>
            <span className='text-center xs:pt-8 xs:px-3 xs:pl-2 py-20 pl-10 font-semibold '>I met a keen observer who gave me a tip: ‘If you run across a restaurant where you often see Those are two classes of people who like to eat well and get their money’s worth.</span>
          </div>
          <div className='w-4/12'>
          </div>
        </div>
      </div>


      {/* send us mail */}

      <div className=' bg-[#F4F4F4] grid md:grid-cols-3 h-[480px]'>
        <div className='w-8/12 xs:hidden'>
          <img src='Image/mix1.png'></img>
        </div>
        <div className=' '>
          <img className='pl-[200px] xs:pl-[120px] pt-10' src='Image/emailk.svg'></img>
          <h1 className='text-center xs:text-4xl sm:text-4xl md:text-4xl font-bold text-[#082161] pt-6 '>Send Us Mail </h1>
          <h2 className='text-center pt-3  font-semibold text-[#000000]  '>Have you any question mails us</h2>
          <div className="max-w-md mx-auto rounded-lg pt-16 overflow-hidden md:max-w-xl">
            <div className="md:flex">
              <div className="w-full p-3">
                <div className="relative"> <input type="text" className="bg-white h-12 w-full px-4 pr-20 rounded-[50px] focus:outline-none " placeholder="Enter your email address" name="" />
                  <button className="h-12  rounded-[50px] bg-[#FEBD11] font-medium absolute top-0 text-sm right-0 px-5 text-white hover:bg-gray-900 duration-300 ">Subscribe Now</button> </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' xs:hidden mt-20 ml-40 w-6/12'>
          <img className=' ' src='Image/drink1 (2).png'></img>
        </div>
      </div>



    </div >

  )
}

export default Home